import React from 'react'

import Container from 'atoms/Container'
import ImageCaptionCardV2 from 'molecules/v1/ImageCaptionCardV2'
import Heading from 'atoms/Heading'
import HorizontalSliderV2 from 'molecules/HorizontalSliderV2'
import { TREATMENT_SLIDER_HOMEPAGE_BREAKPOINTS } from 'constant/patientHomepage'
import { useRouter } from 'next/router'

const ConcernAreaSection = ({ setIsOpenDiseasesModal, concernAreaList, setConcernArea }) => {
    const router = useRouter()

    const onDiseasesConcern = (concernArea) => {
        setConcernArea({ title: concernArea?.disease, specialitiesTitle: 'Symptoms', leftList: concernArea?.symptoms, ...concernArea })
        setIsOpenDiseasesModal(true);
    }

    return (
        <>
            <div className='pb-4'>
                <Container type='static' className=''>
                    <Heading className="font-Montserrat text-18-28 md:text-18-20 text-gray-900 md:mb-0 text-center mt-8 " type='h2' weight={600}>Treatments</Heading>
                    <HorizontalSliderV2
                        className="w-auto h-full ml-2"
                        breakpoints={TREATMENT_SLIDER_HOMEPAGE_BREAKPOINTS}
                        title="Featured Posts"
                        navigationElement={"topslider"}
                        autoplayDelay={2000}
                        spaceBetween={20}
                        sliderList={concernAreaList?.slice(0, -1).map(item => (
                            <ImageCaptionCardV2 className='' key={item.disease} onClick={() => router.push(item.redirection)} src={process.env.NEXT_PUBLIC_PHP_BUCKET_URL + item.webImage} caption={item.disease} />
                        ))}
                    />
                </Container>
            </div>
        </>
    )
}

export default ConcernAreaSection